{
  "exemple_i18n": "Exemple ",
  "fermer_le_menu": "Fermer le menu",
  "la": "La",
  "maniere_la_plus_rapide_pour_trouver_des_films_gratuits": "manière la plus rapide pour trouver des films gratuits !",
  "films_gratis_definition": "Films Gratis est un annuaire de sites de téléchargements et de streaming : il vous permet de trouver sur quels sites et plateformes vous pouvez regarder vos films gratuitement.",
  "copyright": "© Copyright 2023. Tous droits réservés.",
  "films_gratis": "Films Gratis",
  "toggle_menu": "toggle menu",
  "contact": "Contact",
  "accueil": "Accueil",
  "a_propos": "À propos",
  "envoyer": "Envoyer",
  "message": "Message",
  "ton_adresse_mail": "Ton adresse mail",
  "john_doe": "John Doe",
  "ton_nom": "Ton nom",
  "contacte_nous": "Contacte nous !",
  "une_liste_actualisee": "Une liste actualisée en fonction des ouvertures et fermetures des différents sites permettant de visionner et télécharger des films gratuits.",
  "requetes_d_ajout_ou_de_suppression_de_plateformes_suggestion": "Requêtes d'ajout ou de suppression de plateformes, suggestions, petit message ... tu peux tout nous dire !",
  "en_savoir_plus": "En savoir plus",
  "suggestion_de_site": "Suggestion de site",
  "objet_du_message": "Objet du message",
  "moteur_de_recherche": "Moteur de recherche",
  "classement": "Classement",
  "comparateur": "Comparateur",
  "type_de_site": "Type de site",
  "type_de_qualites": "Type de qualités",
  "type_de_categories": "Type de catégories",
  "bandes_sons_disponibles": "Bandes-sons disponibles",
  "langues_disponibles": "Langues disponibles",
  "aucun_site_trouve_a_partir_des_filtres_selectionnes": "Aucun site n'a été trouvé à partir des filtres sélectionnés. :(",
  "recherche": "Recherche...",
  "download": "Les sites de téléchargement direct",
  "torrent": "Les sites de Torrent",
  "streaming": "Les sites de Streaming",
  "sport": "Les sites proposant du Sport",
  "films": "Les sites proposant des films",
  "series": "Les sites proposant des séries",
  "documentary": "Les sites proposant des documentaires",
  "animation": "Les sites proposant des films d'animation",
  "old_films": "Les sites proposant des films anciens",
  "brand_new": "Les sites proposant les dernières nouveautés !",
  "success": "Succès !",
  "message_sent": "Message envoyé !",
  "back_home": "Retour à l'accueil",
  "error": "Erreur ",
  "try_again": "Tente ta chance une prochaine fois !",
  "message_not_sent": "Votre message n'a pas pu être envoyé."
}
